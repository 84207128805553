import * as React from "react";

// type Props = OwnProps;
// type State = Readonly<typeof initialState>;

class DragAndDrop extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { dragging: false };
  }

  render() {
    const { dragging } = this.state;
    return (
      <div
        className={`drop-target ${dragging ? "dragging" : ""}`}
        onDragOver={this.handleDrag} // needs to be here, otherwise the browser does stupid things
        onDrop={this.handleDrop}
        onDragEnter={this.handleDragIn}
        onDragLeave={this.handleDragOut}
      >
        <div className="drag-overlay">Drop file here</div>
        {this.props.children}
      </div>
    );
  }

  handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  };

  handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });
  };
}

export { DragAndDrop };
