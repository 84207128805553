import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import "./display.css";

import { getImages } from "../../api/imageApi";
import ImageView from "./FilteredImageView";

const linkStyle = {
  textDecoration: "none"
};

export default function Slideshow() {
  const [images, setImages] = React.useState([]);

  React.useEffect(() => {
    getImages().then(res => {
      setImages(res);
    });
  }, []);

  const count = images ? images.length : 0;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center"
          alignItems: "center"
        }}
      >
        <div style={{ padding: "20px 0 0 0" }}>
          <Typography variant="h5">Bilder</Typography>
        </div>

        <div className="logo-wrapper">
          <div className="logo" />
        </div>

        <div style={{ padding: "0 0 10px 0" }}>
          {/* <Button variant="outlined" color="primary"> */}
          <Link style={linkStyle} to="/">
            <label className="btn btn-primary" htmlFor="back">
              Gå tilbake
            </label>
          </Link>
          {/* </Button> */}
        </div>

        <div>
          <b>{count}</b>
        </div>
      </div>

      {images && (
        <div className="display-container">
          {images.map((imageUrl, idx) => (
            <ImageView key={idx} imageUrl={imageUrl} />
          ))}
        </div>
      )}
    </>
  );
}
