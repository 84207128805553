import * as React from "react";
import { DragAndDrop } from "./DragAndDrop";

class DragAndDropArea extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { onFileLoad, onFileSelected } = this.props;

    const inputButtonId = `file-input`;
    const mobileInputButtonId = `mobile-file-input`;

    return (
      <div className="drag-and-drop-container">
        <div className="drag-and-drop">
          <DragAndDrop handleDrop={onFileLoad}>
            <div className="content-wrapper">
              <div>Dra og slipp bilde her</div>
              <div>eller</div>
              <input
                type="file"
                name="file-input"
                id={inputButtonId}
                onChange={onFileSelected}
              />
              <label
                className="btn btn-primary button-browse-label"
                htmlFor={inputButtonId}
              >
                Velg bilde
              </label>
            </div>
          </DragAndDrop>
        </div>
        <div className="drag-and-drop-mobile-button">
          <input
            type="file"
            name="file-input"
            id={mobileInputButtonId}
            onChange={onFileSelected}
          />
          <label
            className="btn btn-primary button-browse-label"
            htmlFor={mobileInputButtonId}
          >
            Last opp bilde
          </label>
        </div>
      </div>
    );
  }
}

export { DragAndDropArea };
