import React from "react";
import download from 'image-downloader';
// const download = require('image-downloader')

import "./display.css";

export default React.memo(function ImageView(props) {

  const getFileName = () => {
    const urlPath = props.imageUrl;
    var blobName = urlPath.split("/")[4];
    return blobName.split("__")[0];
  };

  const handleDownload = () => {
    const options = {
      url: props.imageUrl,
      dest: getFileName      // Save to /path/to/dest/photo.jpg
    }
     
    download.image(options)
      .then(({ filename, image }) => {
        console.log('Saved to', filename)  // Saved to /path/to/dest/photo.jpg
      })
      .catch((err) => console.error(err))
  }

  return (<div className="display-item">
  {props.imageUrl && (
    <>
      <div>
        <a
          href={props.imageUrl}
          download
          // target="_blank"
        >
          <img
            id="myImg"
            src={props.imageUrl}
            alt="img_from_blob"
            className="filteredimage"
          />
        </a>
      </div>
      {/* <div>
        <button onClick={handleDownload}>Last ned</button>
      </div> */}
    </>
  )}
</div>
  );
});
